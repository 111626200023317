var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_gray" }),
    _c(
      "div",
      { staticClass: "kefulist" },
      [
        _c("headbox", {
          attrs: {
            title: "联系客服",
            bgColor: "#fff",
            isborder: "true",
            isbackPath: true,
            path: "person"
          }
        }),
        _c("div", { staticClass: "flex kefubox" }, [
          _vm._m(0),
          _c("div", { staticClass: "flex2" }, [
            _c("div", { staticClass: "titlebox" }, [
              _vm._v(
                " " + _vm._s(_vm.processEnv ? "400-860-6806" : "13025498949")
              )
            ]),
            _c("div", { staticClass: "detailsbox" }, [
              _vm._v("人工客服工作时间：9:00-18:00")
            ])
          ]),
          _vm.processEnv
            ? _c("div", { staticClass: "phonekefu" }, [_vm._m(1)])
            : _vm._e(),
          !_vm.processEnv
            ? _c("div", { staticClass: "phonekefu" }, [_vm._m(2)])
            : _vm._e()
        ]),
        _c("center", { staticClass: "orcodebox" }, [
          _c("div", { staticClass: "kefuwechat" }, [
            _c("img", { attrs: { src: _vm.kefuImg, alt: "" } })
          ]),
          _c("div", { staticClass: "h2" }, [
            _vm._v(_vm._s(_vm.GLOBAL) + "企业客服微信")
          ]),
          _c("div", { staticClass: "details" }, [
            _vm._v(" 截图保存在微信中使用扫一扫打开即可享受更多优质服务 ")
          ])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "headbox" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/kefu2.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "tel:4008606806" } }, [
      _c("img", {
        attrs: { src: require("@/assets/images/kefuphone.png"), alt: "" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", { attrs: { href: "tel:13025498949" } }, [
      _c("img", {
        attrs: { src: require("@/assets/images/kefuphone.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }